import store from "../users/store";
import defineAbilityFor from "@/casl/defineAdminAbility";
import ls from "localstorage-slim";
import { getCookies } from "@/helpers/store-cookies";

ls.config.encrypt = true;

const ability = defineAbilityFor(ls.get("perToken"));

const moduleRoute = {
  path: "/dashboard",
  component: () =>
    import(
      /* webpackChunkName: "dashboard-module", webpackPrefetch: true */ "./Module.vue"
    ),
  children: [
    {
      path: "/",
      component: () =>
        import(
          /* webpackChunkName: "dashboard-home", webpackPrefetch: true */ "./views/Home.vue"
        ),
    },
  ],
  // beforeEnter: (to, from, next) => {
  //     if(store.state.token && ability.can('read', 'Dashboard')) {
  //         localStorage.setItem('layout', 'dashboard-layout')
  //         next();
  //         return;
  //     } else if (!ability.can('read', 'Dashboard')) {
  //         localStorage.setItem('layout', 'dashboard-layout')
  //         next('/notAuthorizedForProject');
  //         return;
  //     }
  //     localStorage.setItem('layout', 'landing-layout')
  //     next('/login')
  // }
  beforeEnter: (to, from, next) => {
    if (store.state.token && ability.can("read", "Dashboard")) {
       localStorage.setItem("layout", "dashboard-layout");
      next();
      return;
    }

    // else if(!ls.get('perToken')){
    //     ls.set("perToken", "admin");
    //     localStorage.setItem('layout', 'dashboard-layout')
    //     next();
    //     return;
    // }
    else if (!ability.can("read", "Dashboard")) {
       localStorage.setItem("layout", "dashboard-layout");
      next("/notAuthorizedForProject");
      return;
    } else if (localStorage.getItem("role") == "PM") {
      next("/projects");
    } else if (localStorage.getItem("role") == "reg_user") {
      next("/sales");
    } else if (
      localStorage.getItem("role") == "admin" ||
      localStorage.getItem("role") == "accountant"
    ) {
      next("/dashboard");
    } else if (getCookies("access_token")) {
      localStorage.setItem("layout", "dashboard-layout");
      next();
    }
    localStorage.setItem("layout", "landing-layout");
    next("/");
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
