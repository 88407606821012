export function successToast(msg) {
  var toast = document.getElementById("successToast");
  var successText = document.getElementById("successText");
  successText.innerText = msg;

  toast.className = "show";
   setTimeout(function () {
    toast.classList.remove("show");
  }, 3000);
}
export function warningToast(msg) {
  var toast = document.getElementById("warningToast");
  var warningText = document.getElementById("warningText");
  warningText.innerText = msg;
  toast.className = "show";
  // ('warningToast', toast.className);
  setTimeout(function () {
    toast.classList.remove("show");
  }, 3000);
}

export function errorToast(msg) {
  var toast = document.getElementById("errorToast");
  var errorText = document.getElementById("errorText");
  errorText.innerText = msg;
  toast.className = "show";
  // ('errorToast', toast.className);
  setTimeout(function () {
    toast.classList.remove("show");
  }, 3000);
}
